import React from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './Mission.css';

function MissionItem({ icon, title, description }) {
    return (
        <Card className="mb-4 mission-item">
            <Card.Body>
                <Row>
                    <Col xs="auto">
                        <img
                            src={icon}
                            alt={`${title} icon`}
                            className="mission-icon"
                        />
                    </Col>
                    <Col>
                        <Card.Title className="h3">{title}</Card.Title>
                        <Card.Text className="mission-description">
                            {description}
                        </Card.Text>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}

export default MissionItem;

// PropTypes definitions
MissionItem.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.node,
    ]).isRequired,
};
